import React, { useState, useRef } from 'react';
import {
  directionChartAlienImage,
  directionChartColorBtn,
  directionChartDirectionBtn,
} from '../../User_Profile/User_Quiz_data';
import styles from './DirectionChart.module.scss';
import QuizHeader from '../QuizLayout/QuizHeader/QuizHeader';
import QuizFooterHandleTimer from '../QuizLayout/QuizFooter/QuizFooterHandleTimer';
import cs from 'classnames';
import { attemptSetQuiz } from '../../../store/actions/quizActions';
import { useDispatch } from 'react-redux';
import { setDuration, updateQuizPayload, getCurrentRoute } from '../../../helpers/utility';
import { useReducerData } from '../../../store/hooks';
import ResultModal from '../ResultModal/ResultModal';
import CustomButton from '../../../components/Button';
import { stopQuestionTime } from '../../../helpers/QuestionTimer';
import { useNavigate } from 'react-router';

const DirectionChart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quizData = useReducerData('quiz', 'quizData');
  const tabKey = useReducerData('quiz', 'tabKey');
  const currentQuizKey = useReducerData('quiz', 'currentQuizKey');
  const { key, subKey } = currentQuizKey[+tabKey];
  const questionRef = useRef(null);
  const alienImage = directionChartAlienImage;
  const [attempted, setAttempted] = useState({ activity: 'directionChart', correctAnswer: 0, wrongAnswer: 0 });
  const [activeAlien, setActiveAlien] = useState([0, 0]);
  const [arrowKey, setArrowKey] = useState(null);
  const [squareKey, setSquareKey] = useState(null);
  const [isDisplayNext, setIsDisplayNext] = useState(false);
  const [resultModalVisible, setResultModalVisible] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [isFinishQuestions, setIsFinishQuestions] = useState(false);
  const [isDoneFinish, setIsDoneFinish] = useState(false);
  const url = window.location.href;
  const currentData = quizData?.data?.[+tabKey]?.quiz[key]?.data;

  const arrowClick = (key) => {
    if (squareKey) {
      const alienData = alienImage?.map((element) => element);
      const alienKey = alienData[activeAlien[0]]?.images[activeAlien[1]];
      const matchAlienKey = squareKey === key && key === alienKey.key;
      if (!arrowKey) {
        setArrowKey(key);
        checkAttemptedAnswer(key);
        resetActiveAlien(matchAlienKey);
      } else {
        if (!arrowKey) {
          setArrowKey(key);
        }
      }
    }
  };

  const squareClick = (key) => {
    if (!isFinishQuestions) {
      const alienData = alienImage?.map((element) => element);
      const alienKey = alienData[activeAlien[0]]?.images[activeAlien[1]];
      const matchAlienKey = key === alienKey.key;
      if (arrowKey && !squareKey) {
        setSquareKey(key);
        checkAttemptedAnswer(key);
        resetActiveAlien(matchAlienKey);
      } else {
        if (!squareKey) {
          setSquareKey(key);
        }
      }
    }
  };

  const checkAttemptedAnswer = (key) => {
    if ((arrowKey === key || squareKey === key) && key === alienImage[activeAlien[0]].images[activeAlien[1]].key) {
      const attemptedTemp = attempted;
      attemptedTemp.correctAnswer = attempted.correctAnswer + 1;
      setAttempted(attemptedTemp);
    } else {
      const attemptedTemp = attempted;
      attemptedTemp.wrongAnswer = attempted.wrongAnswer + 1;
      setAttempted(attemptedTemp);
    }
  };

  const resetActiveAlien = async (matchAlienKey) => {
    setTimeout(() => {
      setArrowKey(null);
      setSquareKey(null);
    }, 400);

    const activeAlienTemp = activeAlien;

    if (activeAlienTemp[1] === 8) {
      if (activeAlienTemp[0] === 4 && matchAlienKey) {
        setIsFinishQuestions(true);
        setIsDone(true);
      }

      if (matchAlienKey) {
        setActiveAlien([activeAlienTemp[0] + 1, 0]);
      }
    } else {
      if (matchAlienKey) {
        setActiveAlien([activeAlienTemp[0], activeAlienTemp[1] + 1]);
      }
    }
  };

  const workingDone = async () => {
    setIsDoneFinish(true);
    const tempQuizData = updateQuizPayload(
      quizData.data,
      +tabKey,
      currentQuizKey[+tabKey].key,
      getCurrentRoute(url, currentData),
      setDuration(questionRef.current),
      attempted.wrongAnswer,
      attempted.correctAnswer,
    );

    await dispatch(
      attemptSetQuiz({
        data: tempQuizData?.tempQuizData,
        activity: tempQuizData?.activity,
      }),
    );
    setIsDoneFinish(false);
    setResultModalVisible(true);
  };

  const onTimeOut = async () => {
    const tempQuizData = updateQuizPayload(
      quizData.data,
      +tabKey,
      currentQuizKey[+tabKey].key,
      getCurrentRoute(url, currentData),
      setDuration(questionRef.current),
      attempted.wrongAnswer,
      attempted.correctAnswer,
    );

    await dispatch(
      attemptSetQuiz({
        data: tempQuizData?.tempQuizData,
        activity: tempQuizData?.activity,
      }),
    );
    setResultModalVisible(true);
    setIsDone(true);
  };
  const renderQuiz = (images, index) => {
    return (
      <div className={styles.alignRow}>
        {images.map((el, i) => {
          return (
            <img
              className={cs(styles.alignImg, {
                [styles.align1Active]: activeAlien[0] === index && activeAlien[1] === i && el.key === 1,
                [styles.align2Active]: activeAlien[0] === index && activeAlien[1] === i && el.key === 2,
                [styles.align3Active]: activeAlien[0] === index && activeAlien[1] === i && el.key === 3,
                [styles.align4Active]: activeAlien[0] === index && activeAlien[1] === i && el.key === 4,
              })}
              alt={el.image}
              src={el.image}
            />
          );
        })}
      </div>
    );
  };

  const renderControllerColor = () => {
    return directionChartColorBtn.reduce((prev, curr) => {
      prev.push(
        <div className={styles.btnGroup}>
          {curr.map((el, i) => {
            const Icon = el.image;
            return (
              <Icon
                onClick={() => isDisplayNext && squareClick(el.key)}
                className={cs(styles.btn, {
                  [styles.btnPointer]: isDisplayNext,
                  [styles.btnDisabled]: squareKey === el.key,
                })}
                key={i}
              />
            );
          })}
        </div>,
      );
      return prev;
    }, []);
  };

  const renderControllerDirection = () => {
    return directionChartDirectionBtn.reduce((prev, curr) => {
      prev.push(
        <div className={styles.btnGroup}>
          {curr.map((el, i) => {
            const Icon = el.image;
            return (
              <Icon
                onClick={() => isDisplayNext && arrowClick(el.key)}
                className={cs(
                  styles.btn,
                  { [styles.btnPointer]: isDisplayNext },
                  { [styles.selectedBtn]: arrowKey === el.key },
                )}
                key={i}
              />
            );
          })}
        </div>,
      );
      return prev;
    }, []);
  };

  const handleCloseClick = () => {
    navigate(`/activities`);
  };

  const handleDoneClick = () => {
    stopQuestionTime();
    if (workingDone) {
      workingDone();
    } else {
      setResultModalVisible(true);
    }
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.modalContainer}>
          <QuizHeader
            isDisplayNext={false}
            title={'Direction Charts'}
            // isDone={isDone}
            // workingDone={workingDone}
            // isDoneFinish={isDoneFinish}
          />
          <div className={styles.instruction}>
            Click on the colour of the alien and the direction it is pointing. Moving left to right and top to
            bottom.... Go as fast as you can!
          </div>
          <div className={styles.quizWrapper}>
            <div className={styles.quizController}>
              <div className={styles.color}>{renderControllerColor()}</div>
              <div className={styles.direction}>{renderControllerDirection()}</div>
            </div>
            <div className={styles.quizBody}>{alienImage.map((value, index) => renderQuiz(value.images, index))}</div>
          </div>
          <QuizFooterHandleTimer
            isDisplayNext={isDisplayNext}
            setIsDisplayNext={setIsDisplayNext}
            isDisabled={true}
            questionRef={questionRef}
            onTimeOut={onTimeOut}
          />
          <div className={styles.closeButton} onClick={isDone ? handleDoneClick : handleCloseClick}>
            {isDone && (
              <CustomButton
                type="primary"
                loading={isDoneFinish ? isDoneFinish : false}
                disabled={isDoneFinish ? isDoneFinish : false}
              >
                Done
              </CustomButton>
            )}
          </div>
        </div>
      </div>
      {resultModalVisible && (
        <ResultModal resultModalVisible={resultModalVisible} setResultModalVisible={setResultModalVisible} />
      )}
    </>
  );
};

export default DirectionChart;
