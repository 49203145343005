import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { ReactComponent as CloseButton } from '../../../assets/Font/closeButton.svg';
import { ReactComponent as AlienImage } from '../../../assets/images/HomePage/Programs/DirectionChart/alien2.svg';
import styles from './Result.module.scss';
import CustomButton from '../../../components/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { ResultSnake } from '../../../assets/images/HomePage/Programs/Result/resultSnake';
import { useReducerData } from '../../../store/hooks';
import { attemptSetAlienMovePoint, attemptSetResumeQuiz } from '../../../store/actions/quizActions';
import { useDispatch } from 'react-redux';
import { updateResumeQuiz, getCurrentRoute } from '../../../helpers/utility';
import useWindowSize from '../../../helpers/useWindowSize';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { attemptSetTab } from '../../../store/actions/quizActions';

const ResultModal = ({
  resultModalVisible,
  setResultModalVisible,
  isNextQuiz,
  isNextActivity,
  recordingData,
  isRecodring,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width, height } = useWindowSize();
  const location = useLocation();
  const { state } = location;
  const quizData = useReducerData('quiz', 'quizData');
  const tabKey = useReducerData('quiz', 'tabKey');
  const currentQuizKey = useReducerData('quiz', 'currentQuizKey');
  const alienMove = useReducerData('quiz', 'alienMove');

  const { key } = currentQuizKey[+tabKey];
  const newSubKey = currentQuizKey[+tabKey];
  const url = window.location.href;
  const currentData = quizData?.data?.[+tabKey]?.quiz[key]?.data;
  const elements = quizData?.data?.[+tabKey]?.quiz;
  const subKey = getCurrentRoute(url, currentData);
  const { duration, correctAnswer, questions, redirectTo, rewardText, startAgain, movetoPointFailed } =
    elements[key]?.data[+subKey];
  const [percentage, setPercentage] = useState();
  const moveToPoint = !isNextActivity && isNextQuiz && percentage > 90 ? 74 : elements[key]?.data[+subKey]?.moveToPoint;
  const [findData, setFindData] = useState(elements[key]?.data[subKey + 1]);
  const quizDataMove = elements[key]?.data[subKey];
  const [svg, setSvg] = useState();
  const getQuizName = quizData?.data[parseInt(tabKey)]?.unitName

  const division = questions ? questions : 1;
  const findScore = (correctAnswer / division) * 100;

  const handleClick = () => {
    if (isNextActivity && findScore < 90) {
      const updateQuizData = currentQuizKey[+tabKey];
      updateQuizData.subKey = 0;
      dispatch(attemptSetResumeQuiz(updateQuizData));
      navigate('/activities');
    } else {
      if (isNextQuiz && percentage > 90) {
        const updateQuizData = currentQuizKey[+tabKey];
        updateQuizData.subKey = 0;
        dispatch(attemptSetResumeQuiz(updateQuizData));
        if (key === quizData?.data[tabKey]?.quiz?.length - 1 && getQuizName !== 'Division Unit') {
          dispatch(attemptSetTab(Number(tabKey) + 1));
        }
        navigate('/activities');
      } else {
        const updateQuizData = updateResumeQuiz(subKey, elements, key, tabKey, currentQuizKey, percentage);
        dispatch(attemptSetResumeQuiz(updateQuizData));
        const lastSegment = url?.split('/').pop();
        navigate(findData?.redirectTo);
        if (lastSegment === 'speed-counting') {
          window.location.reload();
        }
      }
    }
  };

  const [animateCount, setAnimateCount] = useState(
    subKey === 0 ? 0 : alienMove[+tabKey] > moveToPoint ? alienMove[+tabKey] - moveToPoint : alienMove[+tabKey],
  );



  useEffect(() => {
    if (resultModalVisible) {
      const id = setInterval(() => setAnimateCount((prev) => prev + 1), 300);
      if (percentage < 90) {
        if (animateCount === movetoPointFailed) {
          clearInterval(id);
          if (subKey === elements[key]?.data?.length - 1) {
            dispatch(attemptSetAlienMovePoint(0));
          } else {
            dispatch(attemptSetAlienMovePoint(animateCount));
          }
        }
      } else {
        if (animateCount === moveToPoint) {
          clearInterval(id);
          if (subKey === elements[key]?.data?.length - 1) {
            dispatch(attemptSetAlienMovePoint(0));
          } else if (subKey === 0 && percentage > 90) {
            dispatch(attemptSetAlienMovePoint(0));
          } else {
            dispatch(attemptSetAlienMovePoint(animateCount));
          }
        }
      }

      return () => {
        clearInterval(id);
      };
    }
  }, [resultModalVisible, animateCount, moveToPoint, movetoPointFailed]);


  useEffect(() => {
    let svgCompo = document.getElementById(moveToPoint === undefined ? '74' : String(animateCount));
    if (subKey === elements[key]?.data?.length - 1 || isNextQuiz) {
      if (+findScore > 90 && +findScore !== 0) {
        if (typeof elements[key + 1] !== "undefined") {
          setPercentage(findScore);
          setFindData(elements[key + 1]?.data[0]);
        }else{
          setPercentage(findScore);
          setFindData({title:'activities'});
        }
      } else {
        if (isNextQuiz && isNextActivity) {
          setFindData(elements[key].data[0]);
          svgCompo = document.getElementById(movetoPointFailed === undefined ? '74' : String(animateCount));
        }
      }
    }
    if (svgCompo) setSvg(svgCompo.getBoundingClientRect());
  }, [animateCount, width, height, svg, moveToPoint]);

  const gobackActivity = () => {
    const lastSegment = url?.split('/').pop();
    if (lastSegment === 'final-quiz') {
      setResultModalVisible(false);
      // navigate(`/activities/number-quiz`);
      navigate(`/`);
      const updateQuizData = currentQuizKey[+tabKey];
      updateQuizData.subKey = 0;
      dispatch(attemptSetResumeQuiz(updateQuizData));
      // window.location.reload();
    } else {
      navigate(`/`);
      setResultModalVisible(false);
      // window.location.reload();
    }
  };

  const resetQuizs = () => {
    let updateQuizData;
    if (isNextQuiz && percentage > 90) {
      updateQuizData = currentQuizKey[+tabKey];
      updateQuizData.subKey = 0;
    } else {
      updateQuizData = updateResumeQuiz(subKey, elements, key, tabKey, currentQuizKey, percentage);
    }
    dispatch(attemptSetResumeQuiz(updateQuizData));
    setResultModalVisible(false);
    // navigate(`/activities`);
    navigate(`/`)
    window.location.reload();
  };

  const getTitle = () => {
    const nextQuiz = quizData?.data[Number(tabKey) + 1]?.unitName
    if (key === quizData?.data[tabKey]?.quiz?.length - 1 && percentage > 90) {
      if (getQuizName === 'Division Unit') {
        return 'Congratulations, you completed the program!'
      } else {
        return `You made it! Go to the ${nextQuiz}.`
      }
    } else {
      return `Go to ${findData?.title}`
    }
  }

  const handleCompleteUnit = () => {
    if (getQuizName !== 'Division Unit') {
      dispatch(attemptSetTab(Number(tabKey) + 1));
    }
    navigate('/activities')
  }
  return (
    <>
      <Modal
        centered
        open={resultModalVisible}
        className={styles.resultModalStyle}
        closable={false}
        footer={null}
      >
        <div className={styles.containerWrapper}>
          <div className={styles.headerContainer}>
            <div className={styles.headerText}>Quiz</div>
            <div className={styles.closeIcon}>
              <CloseButton onClick={resetQuizs} />
            </div>
          </div>
          <div className={styles.mainContainer}>
            <div className={styles.imageWrapper}>
              <ResultSnake style={{ width: '100%' }} />
              <div style={{ left: svg?.x - 10, top: svg?.y - 50, position: 'fixed', transition: '1s' }}>
                <AlienImage />
              </div>
            </div>
            {key === elements.length - 1 && subKey === elements[key].data.length - 1 && percentage > 90 ? (
              <div className={styles.resultWrapper}>
                <div className={styles.timeWrapper}>
                  Great work! You completed the whole unit! If your alien makes it to the end you move to the next unit!
                </div>
                <div className={styles.resultButton}>
                  <CustomButton type="primary" onClick={handleCompleteUnit}>
                    You made it!
                  </CustomButton>
                </div>
              </div>
            ) : (
              <div className={styles.resultWrapper}>
                <div className={styles.timeWrapper}>Your result is {!duration ? '00:00' : duration}</div>
                <div className={styles.greetingWrapper}>
                  {/*Good job! You finished the quiz! Watch your alien move towards the finish line!*/}
                      {subKey !== elements[key]?.data?.length - 1
                    ? findData?.title === 'Figure 8 activity'? 'You have Failed the Quiz. Want to continue with Figure 8 or want to repeat the quiz.' : rewardText
                    : percentage && percentage > 90
                    ? rewardText
                    : startAgain}
                </div>

                <div className={styles.timeWrapper}>
                  Your Score is : {isRecodring ? recordingData : Math.round(findScore)} %
                </div>

                <div className={styles.resultButton}>
                  {/* <CustomButton
                    type="primary"
                    onClick={handleClick}
                    // disabled={animateCount !== moveToPoint && movetoPointFailed !== animateCount }
                  > {getTitle()}
                  </CustomButton> */}
                  <br />
                  <CustomButton
                    onClick={gobackActivity}
                    type="default"
                    className={styles.backWrapper}
                    // disabled={animateCount !== moveToPoint && movetoPointFailed !== animateCount}
                    icon={<ArrowLeftOutlined style={{ fontSize: '18px' }} />}
                  >
                    Go Back
                  </CustomButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ResultModal;
