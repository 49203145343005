import React, { useEffect, useState } from 'react';
import styles from './Tab_Inform.module.scss';
import { useNavigate } from 'react-router-dom';
import { tabs } from '../../quizData/tabs';
import { isLoggedIn } from '../../../helpers/utility';
import { attemptSetModelDetail } from '../../../store/actions/quizActions';
import { useDispatch, useSelector } from 'react-redux';
import { loginUserData } from '../../../store/hooks';
import { notification } from 'antd';
import CustomButton from '../../../components/Button';

const TabInform = ({ tabKey, setProgramName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(isLoggedIn());
  const userData = useSelector((state) => state.auth);
  const { user_subscription, user } = userData;

  useEffect(() => {
    setIsLogin(isLoggedIn());
  }, [user]);

  const handleClick = (elements) => {
    if (isLogin && loginUserData()) {
      if (user_subscription?.length || user?.user_type === 'Admin') {
        if (elements.title === 'Number Quiz') {
          navigate(`/activities`);
        } else if (elements.title === 'Figure 8 activity') {
          navigate('/activities/figure8');
        } else if (elements.title === 'Speed Number Counting') {
          navigate('/activities/speed-counting');
        } else if (elements.title === 'Brain Warrior Workout') {
          navigate('/activities/brain-warrior-workout');
        } else if (elements.title === 'Colour coded memory cards') {
          navigate('/activities/colour-coded');
        } else if (elements.title === 'Direction Charts') {
          navigate('/activities/direction-chart');
        } else if (elements.title === 'Final Quiz') {
          navigate('/activities/final-quiz');
        } else if (elements.title === 'Number Families') {
          navigate('/activities/number-families');
        } else if (elements.title === 'Finger Skip Counting') {
          navigate('/activities/finger-skip-counting');
        }
      } else {
        if (
          (!user_subscription || !user_subscription?.length) &&
          (user?.user_type === 'Children' || user?.user_type === 'Student')
        ) {
          notification['error']({
            message: `Your ${user?.user_type === 'Student' ? "teacher's" : "parent's"}  subscription has been expired.`,
          });
        } else {
          navigate(`/pricing`);
        }
      }
    } else {
      dispatch(attemptSetModelDetail(elements.title));
    }
    setTimeout(() => {
      dispatch(attemptSetModelDetail(''));
    }, 1500);
  };

  const handle = (title) => {
    setProgramName(title);

    setTimeout(() => {
      setProgramName('');
    }, 1000);
  };

  return (
    <>
      <div className={styles.tabInformation}>
        {tabs[parseInt(tabKey)]?.tab.quizList?.map((elements, index) => {
          return (
            <div
              style={{
                background: `url('${elements.neededBackground ? elements.image : null}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
              className={styles.tabDetail}
              key={`-${index}`}
              // onClick={() => {
              //   {
              //     handleClick(elements);
              //   }
              // }}
              onClick={() => handle(elements?.title)}
            >
              <div className={styles.tabHeading}>
                <span>{elements.title}</span>
              </div>
              {!elements.neededBackground && (
                <div>
                  <img src={elements.image} alt={elements.image} width="90%" height="125px" />
                </div>
              )}
              {elements.title !== 'Reward Chart' && (
                <div
                  className={styles.buttonContainer}
                  style={{
                    marginTop:
                      elements.title === 'Brain Warrior Workout'
                        ? '137px'
                        : elements.title === 'Finger Skip Counting'
                        ? '137px'
                        : '10px',
                  }}
                >
                  <CustomButton
                    type="primary"
                    onClick={() => {
                      handleClick(elements);
                    }}
                  >
                    Start Activity
                  </CustomButton>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TabInform;
