import React, { useEffect, useRef, useState } from 'react';
import styles from './Figure8.module.scss';
import QuizHeader from '../QuizLayout/QuizHeader/QuizHeader';
import { attemptSetQuiz, addVideoRecordFile } from '../../../store/actions/quizActions';
import { setDuration, updateQuizPayload, getCurrentRoute } from '../../../helpers/utility';
import { useDispatch } from 'react-redux';
import { useReducerData } from '../../../store/hooks';
import ResultModal from '../ResultModal/ResultModal';
import Canvas from './CanvasYellow/canvas';
// import CanvasBlue from './CanvasBlue/canvasBlue';
import CanvasImage from '../../../assets/images/HomePage/Programs/Figure8/figure5.png';
import QuizFooterHandleTimer from '../QuizLayout/QuizFooter/QuizFooterHandleTimer';
import { stopQuestionTime } from '../../../helpers/QuestionTimer';
import { useReactMediaRecorder } from 'react-media-recorder';
import { CheckMobile } from '../../../helpers/constants';
import { Modal } from 'antd';
import { useNavigate } from 'react-router';
import CustomButton from '../../../components/Button';

const Figure8 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quizData = useReducerData('quiz', 'quizData');
  const tabKey = useReducerData('quiz', 'tabKey');
  const currentQuizKey = useReducerData('quiz', 'currentQuizKey');
  const user = useReducerData('auth', 'user');
  const { key, subKey } = currentQuizKey[+tabKey];
  const questionRef = useRef(null);
  const [isDisplayNext, setIsDisplayNext] = useState(false);
  const [isWrong, setIsWrong] = useState(false);
  const [resultModalVisible, setResultModalVisible] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [isBlue, setIsBlue] = useState(false);
  const [isRed, setIsRed] = useState(false);
  const [countRecord, setCountRecord] = useState(0);
  const [isDoneFinish, setIsDoneFinish] = useState(false);
  // const [approxStartingPnt, setApproxStartingPnt] = useState({ x: 140, y: 140 });
  const [prompt, setPrompt] = useState(' Wrong, Start on the center!');
  const [isModalOpen, setIsModalOpen] = useState(CheckMobile ? true : false);

  const url = window.location.href;
  const currentData = quizData?.data?.[+tabKey].quiz[key].data;
  const handleIsWrong = async (val, n) => {
    if (n === 9) {
      setIsDone(true);
    }

    setIsWrong(val);
  };
  const screen = CheckMobile ? false : true;
  const audio = false;
  const video = false;

  const {
    status,
    startRecording: startRecord,
    stopRecording: stopRecord,
    mediaBlobUrl,
  } = useReactMediaRecorder({ screen, audio, video });

  const startRecording = () => {
    return startRecord();
  };

  const stopRecording = () => {
    return stopRecord();
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden' && status === 'recording') {
        stopRecording();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [status, stopRecording]);

  useEffect(() => {
    if ((status === 'stopped' && mediaBlobUrl) || (CheckMobile && isDone)) {
      handleUpload();
    }
  }, [status, mediaBlobUrl, isDone]);

  const stopVideoRecording = async (videoData) => {
    const response = await fetch(mediaBlobUrl);
    const blob = await response.blob();
    const myFile = new File([blob], `figure8_${new Date()}.mp4`, { type: 'video/mp4' });
    const formData = new FormData();
    formData.append('fig_8_video', myFile);
    formData.append('category', videoData?.category);
    formData.append('sub_category', videoData?.sub_category);
    formData.append('title', 'Figure 8 activity');
    formData.append('is_parent', user?.user_type === 'Children' || user?.user_type === 'Student' ? false : true);
    await dispatch(addVideoRecordFile(formData));
    setIsDoneFinish(false);
  };

  const handleUpload = async () => {
    const count = countRecord ? countRecord : 30;
    const tempQuizData = updateQuizPayload(
      quizData.data,
      +tabKey,
      key,
      getCurrentRoute(url, currentData),
      setDuration(questionRef.current),
      0,
      count,
    );

    setIsDoneFinish(true);
    const videoData = {
      category: tempQuizData?.activity?.category,
      sub_category: tempQuizData?.activity?.sub_category,
    };

    stopVideoRecording(videoData);

    await dispatch(
      attemptSetQuiz({
        data: tempQuizData?.tempQuizData,
        activity: tempQuizData?.activity,
      }),
    );
    setIsDoneFinish(false);
    setResultModalVisible(true);
  };
  const workingDone = () => {
    stopQuestionTime(true);
    stopRecording();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onTimeOut = () => {
    stopQuestionTime(true);
    stopRecording();
  };
  const [instruction, setInstruction] = useState(1);
  const [promptPosition, setPromptPosition] = useState({ x: 85, y: 200 });

  const getSecondDigit = (number) => {
    const strNumber = number.toString();
    if (strNumber.length >= 2) {
      return parseInt(strNumber[1]);
    } else {
      return -1;
    }
  };

  if (CheckMobile) {
    window.addEventListener('click', function (e) {
      var bodyElement = document.body;
      if (document.getElementById('clickbox')?.contains(e?.target)) {
        document.documentElement.classList.add('overflow-hidden');
        bodyElement.style.position = 'fixed';
      } else {
        document.documentElement.classList.remove('overflow-hidden');
        bodyElement.style.position = 'relative';
      }
    });
  }
  const handleCloseClick = () => {
    if (!isDisplayNext) {
      navigate(`/activities`);
    }
  };

  const handleDoneClick = () => {
    stopQuestionTime();
    if (workingDone) {
      workingDone();
    } else {
      setResultModalVisible(true);
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.modalContainer}>
        <QuizHeader
          // isDisplayNext={false}
          title={'Figure 8'}
          // isDone={isDone}
          // isDoneFinish={isDoneFinish}
          // workingDone={workingDone}
        />
        {/*{!isDisplayBlue ? (*/}
        <div className={styles.quizWrapper}>
          {!isDisplayNext ? (
            // <div className={styles.yellowDescWrapper}>
            //   Draw a figure 8 following the yellow arrows 10 times, start in the center and go to your right, stay as
            //   close to the first yellow 8 drawn. This is timed and # of prompts needed to be recorded.
            // </div>
            <div className={styles.informationWrapper}>
              <img src={CanvasImage} height={300} />
              <div className={styles.descWrapper}>
                <p>
                  1. Click on start button, select the current tab, and then click the share button for your teacher's
                  evaluation
                </p>
                <p>2. Follow the yellow arrows creating a figure 8 ten times as fast as you can.</p>
                <p>
                  3. Follow the blue arrows in the opposite direction creating a figure 8 ten times as fast as you can.
                </p>
                <p>4. Draw the numbers 1-9 on the figure 8 using the mouse and keypad.</p>
              </div>
            </div>
          ) : (
            <>
              <div className={styles.instruction}>
                {instruction === 1
                  ? '1. Follow the yellow arrows creating a figure 8 ten times as fast as you can and please lift and release the mouse after each figure 8.'
                  : instruction === 2
                  ? '2. Follow the blue arrows in the opposite direction ten times as fast as you can and please lift and release the mouse after each figure 8.'
                  : instruction === 3
                  ? '3. Draw the numbers 1-9 on the figure 8 and please lift and release the mouse after each figure 8.'
                  : ''}
              </div>
              {countRecord > 20 && countRecord < 30 && (
                <div className={styles.headerText}>
                  <span>{'Draw Number ' + getSecondDigit(countRecord)}</span>
                </div>
              )}
              <div
                className={
                  countRecord > 20 && countRecord < 30
                    ? styles.redArrowWrapper
                    : isBlue
                    ? styles.blueArrowWrapper
                    : styles.yellowArrowWrapper
                }
              >
                {isWrong && (
                  <div
                    className={styles.toolTipText}
                    style={{
                      visibility: 'visible',
                      top: promptPosition.y,
                      left: promptPosition.x,
                      zIndex: '999',
                    }}
                  >
                    {prompt}
                  </div>
                )}
                <div id="clickbox">
                  <Canvas
                    className={styles.canvas}
                    promptPosition={promptPosition}
                    setPromptPosition={setPromptPosition}
                    // setApproxStartingPnt={setApproxStartingPnt}
                    handleIsWrong={handleIsWrong}
                    setIsDisplayNext={setIsDisplayNext}
                    isBlue={isBlue}
                    setIsBlue={setIsBlue}
                    setPrompt={setPrompt}
                    setInstruction={setInstruction}
                    isRed={isRed}
                    setIsRed={setIsRed}
                    setCountRecord={setCountRecord}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {/*) : (*/}
        {/*<div className={styles.quizWrapper}>*/}
        {/*  <div className={styles.blueArrowWrapper}>*/}
        {/*    {isWrong && (*/}
        {/*      <span*/}
        {/*        className={styles.toolTipText}*/}
        {/*        style={{ visibility: 'visible', top: approxStartingPnt.y, left: approxStartingPnt.x }}*/}
        {/*      >*/}
        {/*        Wrong, Start on the center!*/}
        {/*      </span>*/}
        {/*    )}*/}

        {/*    <CanvasBlue*/}
        {/*      className={styles.canvas}*/}
        {/*      approxStartingPnt={approxStartingPnt}*/}
        {/*      handleIsWrong={handleIsWrong}*/}
        {/*      setIsDisplayNext={setIsDisplayNext}*/}
        {/*      setIsDisplayBlue={setIsDisplayBlue}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*)}*/}
        <QuizFooterHandleTimer
          isDisplayNext={isDisplayNext}
          setIsDisplayNext={setIsDisplayNext}
          isDisabled={true}
          questionRef={questionRef}
          onTimeOut={onTimeOut}
          startRecording={CheckMobile ? false : startRecording}
          status={status}
        />
        <div className={styles.buttonContainer}>
          <div className={styles.closeButton} onClick={isDone ? handleDoneClick : handleCloseClick}>
            {isDone && (
              <CustomButton
                type="primary"
                loading={isDoneFinish ? isDoneFinish : false}
                disabled={isDoneFinish ? isDoneFinish : false}
              >
                Done
              </CustomButton>
            )}
          </div>
          {!isDone && (
            <div className={styles.closeButton} onClick={handleUpload}>
              {
                <CustomButton type="primary" loading={isDoneFinish ? isDoneFinish : false}>
                  Skip
                </CustomButton>
              }
            </div>
          )}
        </div>
      </div>
      {resultModalVisible && (
        <ResultModal resultModalVisible={resultModalVisible} setResultModalVisible={setResultModalVisible} />
      )}

      <Modal open={isModalOpen} footer={false} onCancel={handleCancel} centered>
        <p>
          In mobile devices, screen recording is not supported. If screen recording is essential, please consider using
          a laptop or desktop computer.
        </p>
      </Modal>
    </div>
  );
};

export default Figure8;
